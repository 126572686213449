@import '../../styles/mixins/breakpoint.scss';

.main-steper-background-color {
    background-image: url("../../assets/images/steper-back.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: calc(100vh - 331px);
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint("max-xl") {
        min-height: calc(100vh - 300px);
    }

    .box-center-alignment {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}