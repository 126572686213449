@forward "./breakpoint.scss";
@forward "./columns.scss";
@forward "./typography.scss";

.header-hidden {
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
}

.header-show {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
}

.mobile-header-design {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background-color: #fff;

    .mobile-body {
        padding: 40px 20px;

        a {
            cursor: pointer;
            display: block;
            color: #255f50;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            font-family: "Barlow", sans-serif;
            font-style: italic;
            padding: 0 0 40px 0;
        }

        a:last-child {
            padding: 0;
        }
    }

    .mobile-head {
        padding: 20px;
        border-bottom: 2px solid #255f50;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
            cursor: pointer;

            i {
                color: #255f50;
                font-size: 25px;
                cursor: pointer;
            }
        }
    }
}
