.main-new-feed-button-alignment {
    position: fixed;
    top: 50%;
    left: -33px;
    transform: rotate(-90deg);
    z-index: 999999;

    button {
        padding: 10px;
        border: 2px solid var(--green-color);
        color: var(--green-color);
        background-color: #fff;
        font-family: "Inter", sans-serif;
        font-weight: 800;
        font-size: 14px;
        cursor: pointer;
        line-height: 17px;
        cursor: pointer;
    }
}