@import '../../styles/mixins/index.scss';

.news-content-alignment-for-page {
    padding: 120px 0;

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .grid-items {
            .card-image {
                img {
                    width: 100%;
                    height: 260px;
                    object-fit: cover;
                }
            }

            .card-details {
                padding: 10px 0 0 0;

                span {
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    padding: 0 0 10px 0;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                    color: #000;
                    font-weight: 400;
                }

                h6 {
                    font-size: 22px;
                    color: #000;
                    font-weight: 500;
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}