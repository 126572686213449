body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
